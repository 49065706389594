import { graphql } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import groupBy from 'lodash.groupby';
import orderBy from 'lodash.orderby';
import React from 'react';

import Layout from '../components/Layout/Layout';
import useImageQuery from '../utils/use-image-query';
import styles from './bank-direct.module.scss';

import { GatsbyImage } from 'gatsby-plugin-image';
import srcSecurityShield from '../assets/bank-direct/security-shield.svg';
import srcLogoBankDirect from '../assets/bank-direct/smartpay-bank-direct.svg';
import srcAlert from '../assets/icons/alert.svg';
import srcGear from '../assets/icons/gear.svg';
import srcExternalLink from '../assets/icons/icon-external-link.svg';
import { StripType } from '../types';

export interface Bank {
  sys: {
    id: string;
  };
  name: string;
  slug: string;
  sortingOrder: string;
  url: string;
  yomikata: string;
  category: string;
  logo: {
    url: string;
    width: number;
    height: number;
  };
  active: boolean;
}

interface Props {
  data: {
    locales: object;
    contentful: {
      bankCollection: { items: Bank[] };
      stripCollection: { items: StripType[] };
    };
  };
}

type GOJOUN = {
  symbol: string;
  i18nKey:
    | 'gojuon-row-a'
    | 'gojuon-row-ka'
    | 'gojuon-row-sa'
    | 'gojuon-row-ta'
    | 'gojuon-row-na'
    | 'gojuon-row-ha'
    | 'gojuon-row-ma'
    | 'gojuon-row-ya'
    | 'gojuon-row-ra'
    | 'gojuon-row-wa'
    | 'gojuon-row-n';
};

const BANK_LIST_ASSET_URL =
  'https://assets.smartpay.co/bank-direct/smartpay-bank-list-2312.pdf';

const GOJOUN_ROW: GOJOUN[] = [
  { symbol: 'a', i18nKey: 'gojuon-row-a' },
  { symbol: 'ka', i18nKey: 'gojuon-row-ka' },
  { symbol: 'sa', i18nKey: 'gojuon-row-sa' },
  { symbol: 'ta', i18nKey: 'gojuon-row-ta' },
  { symbol: 'na', i18nKey: 'gojuon-row-na' },
  { symbol: 'ha', i18nKey: 'gojuon-row-ha' },
  { symbol: 'ma', i18nKey: 'gojuon-row-ma' },
  { symbol: 'ya', i18nKey: 'gojuon-row-ya' },
  { symbol: 'ra', i18nKey: 'gojuon-row-ra' },
  { symbol: 'wa', i18nKey: 'gojuon-row-wa' },
  { symbol: 'n', i18nKey: 'gojuon-row-n' },
];

export const bankDriectPageQuery = graphql`
  query BankDirectPageQuery($language: String!, $dev: Boolean!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentful {
      stripCollection(
        order: sys_publishedAt_DESC
        limit: 10
        where: { domains_contains_some: "smartpay.co", active: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          link(locale: $language)
          type
          active(locale: $language)
          domains
          paths
          startsFrom
          endsOn
        }
      }
      bankCollection(where: { active: true }, limit: 300, preview: $dev) {
        items {
          gojuonRow
          logo {
            url
            width
            height
          }
          name(locale: $language)
          slug
          sortingOrder
          url
          yomikata
          category
          active
        }
      }
    }
  }
`;

const BankDirectPage: React.FC<Props> = ({ data }) => {
  const { t, originalPath, i18n } = useI18next();
  const {
    srcAppScreenAddBankEn,
    srcAppScreenAddBankJa,
    srcHeroOrderEn,
    srcHeroOrderJa,
    srcIllustrationBankDirect,
  } = useImageQuery();

  const {
    contentful: {
      bankCollection: { items: bankList },
      stripCollection: { items: strips },
    },
  } = data;

  const megaBankList = orderBy(
    bankList.filter((bank) => bank.category === 'Megabank'),
    'sortingOrder'
  );
  const nonMegaBankList = bankList.filter(
    (bank) => bank.category !== 'Megabank'
  );
  const bankGroupByGojuon = groupBy(nonMegaBankList, 'gojuonRow');
  const gojouns = Object.keys(bankGroupByGojuon);

  if (typeof ''.localeCompare !== undefined) {
    gojouns.forEach((gojoun) => {
      (bankGroupByGojuon[gojoun] as Bank[]).sort((a, b) =>
        a.yomikata.localeCompare(b.yomikata, 'ja')
      );
    });
  }

  const srcHeroScreen =
    i18n.language === 'en' ? srcAppScreenAddBankEn : srcAppScreenAddBankJa;

  const srcHeroOrder = i18n.language === 'en' ? srcHeroOrderEn : srcHeroOrderJa;

  return (
    <Layout
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      rootClassName={styles['root-wrapper']}
      strips={strips}
    >
      <section className={styles.hero}>
        <div className={styles.wrapper}>
          <img
            src={srcLogoBankDirect}
            alt={t('bank-direct-title')}
            width="668"
            className={styles.logo}
          />
          <figure>
            <GatsbyImage
              className={styles.heroScreen}
              image={srcHeroScreen.childImageSharp.gatsbyImageData}
              alt=""
            />
            <img
              src={srcSecurityShield}
              className={styles.heroShield}
              width="178"
              height="178"
              alt=""
            />
            <GatsbyImage
              className={styles.heroOrder}
              image={srcHeroOrder.childImageSharp.gatsbyImageData}
              alt=""
            />
          </figure>
        </div>
      </section>

      <section className={styles.aboutContainer}>
        <div className={styles.about}>
          <div className={styles.box}>
            <GatsbyImage
              image={srcIllustrationBankDirect.childImageSharp.gatsbyImageData}
              alt=""
              className={styles.illustration}
            />
            <div>
              <h2>{t('about-bank-direct-title')}</h2>
              <Trans
                i18nKey="about-bank-direct-desc"
                components={{ p: <p />, b: <b /> }}
              />
              <p className={styles.note}>
                {t('smartpay-bank-direct-desc-extra')}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.bankListContainer}>
        <div className={styles.bankList}>
          <div className={styles.box}>
            <h2>{t('bank-direct-available-banks')}</h2>
            <h3>{t('bank-direct-available-banks-sub')}</h3>

            <div className={styles.alerts}>
              <div className={styles.alert}>
                <img src={srcAlert} alt="" width="64" />
                <p>
                  <Trans
                    i18nKey="bank-direct-note-1"
                    components={{ br: <br />, b: <b /> }}
                  />
                </p>
              </div>
              <div className={styles.alert}>
                <img src={srcGear} alt="" width="64" />
                <p>
                  <Trans
                    i18nKey="bank-direct-note-2"
                    components={{ br: <br />, b: <b /> }}
                  />
                </p>
              </div>
            </div>

            <h3>{t('bank-direct-cat-megabanks')}</h3>

            <table className={styles.megaBanks}>
              <thead>
                <tr>
                  <th className={styles.center}>
                    {t('bank-direct-bank-name')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {megaBankList.map((bank) => {
                  return (
                    <tr key={bank.slug}>
                      <th className={styles.center}>
                        {bank.url ? (
                          <a
                            href={bank.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={bank.logo.url}
                              alt={bank.name}
                              width={bank.logo.width / 2}
                            />
                          </a>
                        ) : (
                          <img
                            src={bank.logo.url}
                            alt={bank.name}
                            width={bank.logo.width / 2}
                          />
                        )}
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <hr />

            <h3>
              {t('bank-direct-cat-local-banks')}
              <span>{' ・ '}</span>
              {t('bank-direct-cat-labor-banks')}
              <span>{' ・ '}</span>
              {t('bank-direct-cat-credit-unions')}
            </h3>

            <nav className={styles.gojuonNav}>
              {GOJOUN_ROW.filter((entry) => {
                const bankList = bankGroupByGojuon[entry.symbol] as Bank[];

                return bankList?.length;
              }).map((entry, index) => {
                return (
                  <React.Fragment key={entry.symbol}>
                    {index ? '|' : ''}
                    <a href={`#gojuon-row-${entry.symbol}`}>
                      {t(entry.i18nKey)}
                    </a>
                  </React.Fragment>
                );
              })}
            </nav>

            {GOJOUN_ROW.map((entry) => {
              const bankList = bankGroupByGojuon[entry.symbol] as Bank[];

              return bankList?.length ? (
                <>
                  <h4 id={`gojuon-row-${entry.symbol}`}>{t(entry.i18nKey)}</h4>
                  <table className={styles.nonMegaBanks}>
                    <thead>
                      <tr>
                        <th>{t('bank-direct-bank-name')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bankList.map((bank) => {
                        return (
                          <tr key={bank.slug}>
                            <td>
                              {bank.url ? (
                                <a
                                  href={bank.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {bank.name}
                                  <img
                                    src={srcExternalLink}
                                    alt=""
                                    width="20"
                                    height="20"
                                  />
                                </a>
                              ) : (
                                bank.name
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : null;
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BankDirectPage;
